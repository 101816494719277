<template>
    <v-dialog v-model="dialogUpload" max-width="450px">
      <v-card>
        <v-card-title>
          <span class="title">{{title}}</span>
        </v-card-title>
        <v-card-text>
            <div class="flex w-full h-screen items-center justify-center text-center" id="app">
                <div class="p-12 bg-gray-100 border border-gray-300" @dragover="dragover" @dragleave="dragleave" @drop="drop">
                    <input type="file" multiple name="fields[assetsFieldHandle][]" id="assetsFieldHandle" style="display:none" class="w-px h-px opacity-0 overflow-hidden absolute" @input="onChange" ref="file" accept=".xls, .xlsx" />
                
                    <label for="assetsFieldHandle" class="block cursor-pointer">
                    <div>
                        <p v-if="temp_file"><a :href="temp_file" target="blank">Tệp mẫu</a></p>
                        Kéo tệp excel vào đây hoặc 
                        <span class="underline">bấm vào đây</span> để chọn tệp.
                    </div>
                    </label>
                    <ul class="mt-4" v-if="this.filelist.length" v-cloak>
                    <li class="text-sm p-1" v-for="file in filelist" :key="file.name">
                        {{ file.name }}<button class="ml-2 remove" type="button" @click="remove(filelist.indexOf(file))" title="Xóa file">xóa file</button>
                    </li>
                    </ul>
                </div>
            </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1 white--text" @click="closePopup">Đóng</v-btn>
          <v-btn color="red darken-1 white--text" @click="importFile">OK</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
</template>

<script>
import _ from "lodash";
export default {
  name: "upload-file-popup",
  data() {
    return {
      filelist: [] // Store our uploaded files
    };
  },
  props: ["dialogUpload", "title", "temp_file"],
  methods: {
    closePopup() {
      this.$emit("closePopup");
    },
    importFile() {
      this.$emit("importFile", this.filelist);
    },
    onChange() {
      this.filelist = [...this.$refs.file.files];
      this.$refs.file.value = null;
    },
    remove(i) {
      this.filelist.splice(i, 1);
    },
    dragover(event) {
      event.preventDefault();
      // Add some visual fluff to show the user can drop its files
      if (!event.currentTarget.classList.contains('bg-green-300')) {
        event.currentTarget.classList.remove('bg-gray-100');
        event.currentTarget.classList.add('bg-green-300');
      }
    },
    dragleave(event) {
      // Clean up
      event.currentTarget.classList.add('bg-gray-100');
      event.currentTarget.classList.remove('bg-green-300');
    },
    drop(event) {
      event.preventDefault();
      this.$refs.file.files = event.dataTransfer.files;
      this.onChange(); // Trigger the onChange event manually
      // Clean up
      event.currentTarget.classList.add('bg-gray-100');
      event.currentTarget.classList.remove('bg-green-300');
    }   
  },
  mounted() {
    this.$store.dispatch("commons/getAllStores");
  }
};
</script>
<style scoped>
[v-cloak] {
  display: none;
}
.border {
    border: 1px solid #a7a7a7
}
ul {
  list-style: none
}
.remove{
    color: rgb(245, 8, 8)
}
</style>