<template>
  <v-container grid-list-xl fluid>
    <v-layout row wrap>
      <v-flex lg12>
        <v-card>
          <hpo-filter
            :headers="headers"
            :viewName="viewName"
            v-on:applyFilter="applyFilter"
            v-on:quickFilter="quickFilter"
          ></hpo-filter>
          <v-data-table fixed-header height="750px"  
            :headers="headers"
            :items="items"
            :options.sync="pagination"
            :server-items-length="total_rows"
            :footer-props="items_per_page"
            :must-sort="true"
            :loading="loading"
            :mobile-breakpoint="100"
          >
            <template v-slot:item="{ item }">
              <tr :id="'r-'+item.ID">
                <td>{{ item.TransactionCode }}</td>
                <td v-if="!isDepositOrder">{{ item.OrderID > 0 ? (item.PackageType == 0 ? 'MD' : 'KG') + item.OrderID : ''}}</td>
                <td>{{ item.Username }}</td>
                <td>{{ item.ProductType | display_value(product_types) }}</td>
                <td class="text-end">
                  <v-edit-dialog :return-value.sync="item.RealNumberOfPack" large lazy persistent @save="saveItem({'ID': item.ID, 'RealNumberOfPack': item.RealNumberOfPack})">
                    <div>{{ item.RealNumberOfPack | currency }}</div>
                    <template v-slot:input>
                      <v-text-field v-model="item.RealNumberOfPack" type="number" step="1" :label="isChinessLanguage ? '数量' : 'Số lượng'" single-line autofocus></v-text-field>
                    </template>
                  </v-edit-dialog>
                </td>
                <td class="text-end">
                  <v-edit-dialog :return-value.sync="item.ChinaWeight" large lazy persistent @save="saveItem({'ID': item.ID, 'ChinaWeight': item.ChinaWeight})">
                    <div>{{ item.ChinaWeight | currency('', 2) }}</div>
                    <template v-slot:input>
                      <v-text-field v-model="item.ChinaWeight" type="number" step=".1" :label="isChinessLanguage ? '重量' : 'Cân nặng'" single-line autofocus></v-text-field>
                    </template>
                  </v-edit-dialog>
                </td>
                <td class="text-end">
                  <v-edit-dialog :return-value.sync="item.ChinaLength" large lazy persistent @save="saveItem({'ID': item.ID, 'ChinaLength': item.ChinaLength})">
                    <div>{{ item.ChinaLength | currency }}</div>
                    <template v-slot:input>
                      <v-text-field v-model="item.ChinaLength" type="number" step="1" :label="isChinessLanguage ? '长' : 'Dài'" single-line autofocus></v-text-field>
                    </template>
                  </v-edit-dialog>
                </td>
                <td class="text-end">
                  <v-edit-dialog :return-value.sync="item.ChinaWidth" large lazy persistent @save="saveItem({'ID': item.ID, 'ChinaWidth': item.ChinaWidth})">
                    <div>{{ item.ChinaWidth | currency }}</div>
                    <template v-slot:input>
                      <v-text-field v-model="item.ChinaWidth" type="number" step="1" :label="isChinessLanguage ? '宽' : 'Rộng'" single-line autofocus></v-text-field>
                    </template>
                  </v-edit-dialog>
                </td>
                <td class="text-end">
                  <v-edit-dialog :return-value.sync="item.ChinaHeight" large lazy persistent @save="saveItem({'ID': item.ID, 'ChinaHeight': item.ChinaHeight})">
                    <div>{{ item.ChinaHeight | currency }}</div>
                    <template v-slot:input>
                      <v-text-field v-model="item.ChinaHeight" type="number" step="1" :label="isChinessLanguage ? '高' : 'Cao'" single-line autofocus></v-text-field>
                    </template>
                  </v-edit-dialog>
                </td>
                <td v-if="!exportMenu && (!item.BigPackageID || getBigPackage(item.BigPackageID) != '')">
                  <v-edit-dialog
                    :return-value.sync="item.BigPackageID"
                    large
                    lazy
                    persistent
                    @save="saveItem({'ID': item.ID, 'BigPackageID': item.BigPackageID})"
                  >
                    <div>{{ item.PackageCode }}</div>
                    <template v-slot:input>
                      <v-autocomplete
                        :items="packages"
                        v-model="item.BigPackageID"
                        item-text="PackageCode"
                        item-value="ID"
                        clearable
                        v-on:change="item.BigPackageID = item.BigPackageID || 0; item.PackageCode = getBigPackage(item.BigPackageID)"
                      ></v-autocomplete>
                    </template>
                  </v-edit-dialog>
                </td>
                <td v-else>
                  {{item.PackageCode}}
                </td>
                <td>{{ item.StoreCNImportDate | display_date }}</td>
                <td v-if="!exportMenu">{{ item.PackDate | display_date }}</td>
                <td v-if="exportMenu">{{ item.StoreCNExportDate | display_date }}</td>
                <td class="truncate" v-if="!exportMenu">
                  <v-edit-dialog :return-value.sync="item.StoreCNNote" large lazy persistent @save="saveItem({'ID': item.ID, 'StoreCNNote': item.StoreCNNote})">
                      <v-tooltip top right max-width="250">
                          <template v-slot:activator="{ on }">
                              <span v-on="on">{{ item.StoreCNNote }}</span>
                          </template>
                          <span>{{ item.StoreCNNote }}</span>
                      </v-tooltip>
                      <template v-slot:input>
                          <v-textarea v-model="item.StoreCNNote" :label="isChinessLanguage ? '笔记' : 'Ghi chú'" single-line autofocus></v-textarea>
                      </template>
                  </v-edit-dialog>
                </td>
                <td class="text-center" v-if="!exportMenu">
                  <v-icon small v-bind:style="{ 'color': item.Packages && item.Packages.length > 0 ? '#dc3545' : 'gray'}" @click="duplicateItem(item.ID)">widgets</v-icon>
                  <v-icon small color="#dc3545" @click="printTransaction(item.ID)">fa-print</v-icon>
                  <v-tooltip top>
                      <template v-slot:activator="{ on }">
                          <v-icon v-on="on" v-if="(storeRole & 4)" medium @click="deleteItem(item.ID)" :disabled="item.BigPackageID > 0">fa-trash-alt</v-icon>
                      </template>
                      <span>Xóa mã bill</span>
                  </v-tooltip>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-card>
      </v-flex>
      <store-popup
        :dialogStoreManager="dialogStoreManager"
        v-on:closePopup="closePopup"
        @deleteItem="deleteItem"
      ></store-popup>
      <v-overlay :value="appLoading" z-index="999999">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
    </v-layout>
    <upload-file :dialogUpload="dialogImport" title="Import mã vận đơn" temp_file="package_import_template.xls" @closePopup="dialogImport=false" @importFile="importFile"></upload-file>
    <split-package
          :dialogSplitPackage="splitDialog"
          :currentItem="currentItem"
          v-on:closePopup="splitDialog = false"
    ></split-package>
  </v-container>
</template>

<script>
import { mapState } from "vuex";
import configs from "../../configs";
import Filtering from "@/components/Filtering";
import store from "@/components/popups/store";
import { sendErrorNotice } from "@/commons/utils";
import _ from "lodash";
import { print_transaction_v2, request_detail } from "@/commons/hpod";
import upload_file from "@/components/popups/upload_file";
import split_package from "@/components/popups/split_package";
import Vue from 'vue';

export default {
  components: {
    "hpo-filter": Filtering,
    "store-popup": store,
    "upload-file": upload_file,
    "split-package": split_package
  },
  props: ["exportMenu"],
  data() {
    return {
      splitDialog: false,
      isDepositOrder: configs.SYSTEM_TYPE != 'order',
      pagination: {  ...configs.PAGINATION,  sortBy: ["ID"]  },
      items_per_page: _.cloneDeep(configs.ITEMS_PER_PAGE),
      viewName: "storemanager_list",
      filterConditions: [],
      quickFilterConditions: [],
      requireRules: [v => !!v || "Trường dữ liệu không được để trống."],
      dialogStoreManager: false,
      dialogEditTranCode: false,
      dialogImport: false,
      createDepositOrder: false,
      appLoading: false
    };
  },
  watch: {
    pagination: {
      handler: function(val, oldVal) {
        let filterConditions = this.$store.state.commons.filterConditions;
        this.filterConditions = !_.isEmpty(filterConditions)
          ? filterConditions[this.viewName]
          : [];
        this.filter_data();
      },
      deep: true
    },
    storeRole() {
      this.$root.$emit('REQUEST_ROOT_OPTION', {invisibleCreateButton: !(this.storeRole & 2)});
    },
    product_types() {
      let header_item = _.find(this.headers, { value: "ProductType" });
      if (header_item) {
          header_item.values = this.product_types;
      }
    }
  },
  computed: {
    ...mapState({
      items: state => state.orderTransaction.all.data,
      total_rows: state => state.orderTransaction.all.total,
      currentItem: state => state.orderTransaction.selected,
      loading: state => state.orderTransaction.loading,
      packages: state => state.bigPackage.inTQ,
      customers: state => state.customer.master.data,
      configuration: state => state.configuration.selected
    }),
    storeRole() {
      return this.$store.state.authentication.role.StoreCN;
    },
    isChinessLanguage() {
      return (this.$store.state.authentication.user.roletype & configs.ROLE_TYPE.StoreTQ) == configs.ROLE_TYPE.StoreTQ && this.$store.state.authentication.user.ischinesslanguage;
    },
    param() {
      let pr = {
        pagination: this.pagination,
        filter: this.filterConditions,
        quickFilter: this.quickFilterConditions
      };
      if(pr.pagination.sortBy == 'ID') {
        pr.pagination.sortBy = this.exportMenu ? ['StoreCNExportDate'] : ['StoreCNImportDate'];
      }
      if(!this.exportMenu) {
        pr.filter.push({
          column: "Status",
          value: [configs.PACKAGE_STATUS_ENUM.DA_VE_TQ, configs.PACKAGE_STATUS_ENUM.DANG_VE_VN],
          condition: "in"
        });
      }
      else { 
        pr.filter.push({
          column: "Status",
          value: configs.PACKAGE_STATUS_ENUM.DANG_VE_VN,
          condition: "equal"
        });
      }
      return pr;
    },
    product_types() {
      return this.configuration.ProductTypes ? JSON.parse(this.configuration.ProductTypes) : [];
    },
    headers() {
      let tmp = [
        {
          text: this.isChinessLanguage ? "单号" : "Mã bill",
          value: "TransactionCode",
          filterable: true,
          quickSearch: true,
          dataType: configs.DATA_TYPE["String"]
        },
        {
          text: this.isChinessLanguage ? "客户名" : "Khách hàng",
          value: "Username",
          filterable: true,
          dataType: configs.DATA_TYPE["String"]
        },
        {
          text: this.isChinessLanguage ? "分类" : "Loại hàng",
          value: "ProductType",
          filterable: true,
          values: [],
          dataType: configs.DATA_TYPE["Enum"]
        },
        {
          text: this.isChinessLanguage ? "数量" : "Số lượng",
          value: "RealNumberOfPack",
          filterable: true,
          dataType: configs.DATA_TYPE["Number"]
        },
        {
          text: this.isChinessLanguage ? "重量" : "Cân nặng",
          value: "ChinaWeight",
          filterable: true,
          decimal: 1,
          dataType: configs.DATA_TYPE["Number"]
        },
        {
          text: this.isChinessLanguage ? "长" : "Dài",
          value: "ChinaLength",
          filterable: true,
          dataType: configs.DATA_TYPE["Number"]
        },
        {
          text: this.isChinessLanguage ? "宽" : "Rộng",
          value: "ChinaWidth",
          filterable: true,
          dataType: configs.DATA_TYPE["Number"]
        },
        {
          text: this.isChinessLanguage ? "高" : "Cao",
          value: "ChinaHeight",
          filterable: true,
          dataType: configs.DATA_TYPE["Number"]
        }
      ];
      if(!this.isDepositOrder) {
        temp_headers.splice(2, 0, {
          text: this.isChinessLanguage ? "订单号" : "Mã đơn",
          value: "OrderID",
          filterable: true,
          quickSearch: true,
          dataType: configs.DATA_TYPE["Number"]
        });
      }
      if(!this.exportMenu) {
        tmp.push(
          {
            text: this.isChinessLanguage ? "包裹编号" : "Mã bao hàng",
            value: "PackageCode",
            filterable: true,
            quickSearch: true,
            dataType: configs.DATA_TYPE["String"]
          }
        )
      }
      tmp.push(
        {
          text: this.isChinessLanguage ? "入仓日期" : "Ngày nhập kho",
          value: "StoreCNImportDate",
          filterable: true,
          dataType: configs.DATA_TYPE["Date"]
        }
      )
      if(!this.exportMenu) {
        tmp.push(
          {
            text: this.isChinessLanguage ? "打包日期" : "Ngày đóng bao",
            value: "PackDate",
            filterable: true,
            dataType: configs.DATA_TYPE["Date"]
          }
        )
      }
      if(this.exportMenu) {
        tmp.push(
          {
            text: this.isChinessLanguage ? "出货日期" : "Ngày xuất kho",
            value: "StoreCNExportDate",
            filterable: true,
            dataType: configs.DATA_TYPE["Date"]
          }
        )
      }
      if(!this.exportMenu) {
        tmp.push(
          {
            text: this.isChinessLanguage ? "笔记" : "Ghi chú",
            value: "StoreCNNote",
            filterable: true,
            dataType: configs.DATA_TYPE["String"]
          }
        )
        tmp.push(
          {
            text: this.isChinessLanguage ? "操作" : "Thao tác",
            value: "",
            align: "center",
            sortable: false,
            filterable: false
          }
        )
      }
      return tmp;
    }
  },
  methods: {
    filter_data: function() {
      this.$store.dispatch("bigPackage/getListInTQ");
      this.$store.dispatch("orderTransaction/getList", this.param);
      this.getCustomerList();
      this.appLoading = false;
    },
    gotoDetail: function(id) {
      let item = _.find(this.items, { ID: id });
      this.$store.commit("orderTransaction/setDetail", { data: item });
      this.dialogEditTranCode = true;
    },
    deleteItem: function(id) {
      window.getApp.$emit("REQUEST_DELETE_DATA", id);
    },    
    applyFilter(filterConditions) {
      let sortBy = this.pagination.sortBy;
      let sortDesc = this.pagination.sortDesc;
      this.pagination = configs.PAGINATION;
      this.pagination.sortBy = sortBy;
      this.pagination.sortDesc = sortDesc;
      this.filterConditions = filterConditions;
      this.filter_data();
    },
    quickFilter(filterConditions) {
      this.quickFilterConditions = filterConditions;
      this.filter_data();
    },
    createData(username = "") {
      // Bỏ qua event nếu call từ menu xuất kho tq
      if(this.exportMenu) {
        return;
      }
      if(this.storeRole & 2) {
        this.$store.commit("orderTransaction/setDetail", {
          data: {
            ID: 0,
            TransactionCode: "",
            ProductType: -1
          }
        });
        this.dialogStoreManager = true;
      }
      else {
        sendErrorNotice("Bạn không có quyền thêm mới!");
      }
    },
    saveItem(item) {
      this.$store.dispatch("orderTransaction/setDetail", {
        data: {
          ...item,
          'TransactionView': 'store_tq'
        },
        id: item.ID,
        param: this.param
      });
    },
    saveData() {
      this.saveItem(this.currentItem);
      this.dialogEditTranCode = false;
    },
    closePopup() {
      this.dialogStoreManager = false;
      this.filter_data();
    },
    duplicateItem(id) {
      this.$store.dispatch("orderTransaction/getDetail", id);
      this.splitDialog = true;
    },
    getBigPackage(bigPackageID) {
      const item = _.find(this.packages, {ID: bigPackageID});
      return item ? item.PackageCode : '';
    },
    importFileDialog() {
      this.dialogImport = true;
    },
    importFile(filelist) {
        for(const f of filelist) {
            const formData = new FormData();
            formData.append('excel_file', f);
            formData.append('filename', f.name);
            this.$store.dispatch("orderTransaction/upload_import_cn_store", formData);
        }
        this.dialogImport = false;
    },
    getCustomerList() {
      this.$store.dispatch("customer/getCustomerList", {
          is_master: 1,
          fields: ["ID", "Username", "CustomerCode"]
      });
    },
    printTransaction(id) {
      this.appLoading = true;
      request_detail('order_transactions/print', ret => {
        let item = ret.data;
        this.appLoading = false;
        const frame_printing = print_transaction_v2(item, this.$store.state.authentication.user.username, true, null, item.Packages != null);
        var doc = document.getElementById('print_frame').contentWindow.document;
        doc.open();
        doc.write(frame_printing);
        doc.close();
        setTimeout(function(){ window.frames["print_frame"].window.print(); }, 1000);
      }, Vue.http, id, this.$store.state.authentication.token);
    }
  },
  mounted() {
    this.$store.dispatch("configuration/getDetail", 1);
    this.$root.$emit('REQUEST_ROOT_OPTION', {invisibleCreateButton: !(this.storeRole & 2)});
    this.$root.$on("REQUEST_CREATE_DATA", this.createData);
    this.$root.$on("REQUEST_REFRESH_DATA", this.filter_data);
    this.$root.$on("REQUEST_SAVE_DATA", this.importFileDialog);
    this.$root.$on("CONFIRMED_REMOVE_DATA", id => {
      this.$store.dispatch("orderTransaction/setDetail", {
        data: {
          'RequestAction': 'DEL',
          'TransactionView': 'store_cn'
        },
        'id': id,
        param: this.param
      });
    });
  },
  beforeDestroy() {
    this.$root.$off("REQUEST_CREATE_DATA", this.createData);
    this.$root.$off("REQUEST_REFRESH_DATA", this.filter_data);
    this.$root.$off("REQUEST_SAVE_DATA", this.importFileDialog);
    this.$root.$off("CONFIRMED_REMOVE_DATA");
  }
};
</script>
