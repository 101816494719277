<template>
  <v-dialog v-model="dialogStoreManager" fullscreen hide-overlay persistent transition="dialog-bottom-transition">
    <v-card>
      <v-toolbar dark color="oee-theme">
        <v-btn icon dark @click="closePopup">
          <v-icon>fa-times-circle</v-icon>
        </v-btn>
        <v-spacer></v-spacer>
        <v-toolbar-title>{{isChinessLanguage ? '中国入仓' : 'Nhập kho ngày'}} {{currentTime | display_date('DD-MM-YYYY')}}</v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <v-layout row wrap pa-3>
          <v-flex lg3 md12 sm12>
            <v-layout row wrap pa-3>
              <v-flex lg12 md12 sm12 xs12 pa-0 ma-0>
                <v-checkbox v-if="!useSigned" :label="isChinessLanguage ? '到付/重量' : 'Phí ship/kích thước/cân nặng'" v-model="useOtherField"></v-checkbox>
                <v-checkbox v-if="!useSigned" :label="isChinessLanguage ? '电商输入信息' : 'Nhập thông tin TMĐT'" v-model="useTMDT"></v-checkbox>
                <v-checkbox v-if="!useTMDT" :label="isChinessLanguage ? '扫码' : 'Bắn mã ký nhận'" v-model="useSigned"></v-checkbox>
                <v-checkbox :label="isChinessLanguage ? '导入批次' : 'Nhập kiện lô'" v-model="useSameCode"></v-checkbox>
              </v-flex>
              <v-flex lg12 md12 sm12 xs12 v-if="!useSigned">
                <v-autocomplete class="input-group--focused" append-icon='' clearable :label="isChinessLanguage ? '客户名' : 'Khách hàng'" v-model="selectedUID" :items="customers" item-text="CustomerCode" item-value="ID" @keydown.enter="focusNext"></v-autocomplete>
              </v-flex>
              <v-flex lg12 md12 sm12 xs12 pa-0 v-if="!useTMDT && !useSigned">
                <v-autocomplete clearable class="input-group--focused" append-icon='' :label="isChinessLanguage ? '分类' : 'Loại hàng'" v-model="selectedType" :items="product_types" item-text="name" item-value="value" @keyup.enter="focusNext"></v-autocomplete>
              </v-flex>
              <v-flex lg12 md12 sm12 xs12 pa-0 v-if="!useSigned">
                <v-text-field
                  :label="isChinessLanguage ? '入单号(enter)' : 'Nhập mã bill (enter)'"
                  ref="trancode"
                  v-model="currentItem.TransactionCode"
                  class="input-group--focused"
                  :rules="requireRules"
                  @keyup.enter="checkTransaction"
                ></v-text-field>
              </v-flex>
              <v-flex lg12 md12 sm12 xs12 pa-0 v-else>
                <v-text-field
                  :label="isChinessLanguage ? '入单号(enter)' : 'Nhập mã bill (enter)'"
                  ref="trancode"
                  v-model="currentItem.TransactionCode"
                  class="input-group--focused"
                  :rules="requireRules"
                  @keyup.enter="enterTransaction"
                ></v-text-field>
              </v-flex>
              <v-flex lg12 md12 sm12 xs12 pa-0 v-if="useSigned || useOtherField">
                <h-currency-input
                  v-model="currentItem.ChinaWeight"
                  :decimal="1"
                  :label="isChinessLanguage ? '重量' : 'Cân nặng'"
                  class="input-group--focused"
                  @press_enter="onChangeWeight"
                ></h-currency-input>
              </v-flex>
              <v-flex lg12 md12 sm12 xs12 pa-0 v-if="!useTMDT && !useSigned && useOtherField">
                <h-currency-input
                  v-model="currentItem.ChinaLength"
                  :decimal="0"
                  :label="isChinessLanguage ? '长 (cm)' : 'Chiều dài (cm)'"
                  class="input-group--focused"
                  @press_enter="focusNext"
                ></h-currency-input>
              </v-flex>
              <v-flex lg12 md12 sm12 xs12 pa-0 v-if="!useTMDT && !useSigned && useOtherField">
                <h-currency-input
                  v-model="currentItem.ChinaWidth"
                  :decimal="0"
                  :label="isChinessLanguage ? '宽 (cm)' : 'Chiều rộng (cm)'"
                  class="input-group--focused"
                  @press_enter="focusNext"
                ></h-currency-input>
              </v-flex>
              <v-flex lg12 md12 sm12 xs12 pa-0 v-if="!useTMDT && !useSigned && useOtherField">
                <h-currency-input
                  v-model="currentItem.ChinaHeight"
                  :decimal="0"
                  :label="isChinessLanguage ? '高 (cm)' : 'Chiều cao (cm)'"
                  class="input-group--focused"
                  @press_enter="focusNext"
                ></h-currency-input>
              </v-flex>
              <v-flex lg12 md12 sm12 xs12 pa-0 v-if="useSameCode">
                <h-currency-input
                  v-model="currentItem.RealNumberOfPack"
                  :decimal="0"
                  :label="isChinessLanguage ? '数量' : 'Số lượng'"
                  class="input-group--focused"
                  @press_enter="focusNext"
                ></h-currency-input>
              </v-flex>
              <v-flex lg12 md12 sm12 xs12 pa-0 v-if="useTMDT && !useSigned">
                <h-autocomplete-input :label="isChinessLanguage ? '物品名称' : 'Tên hàng hóa'" v-model="currentItem.ProductName" :items="productNames" item_text="name" item_value="value" :autoEmit="false" @press_enter="focusNext"></h-autocomplete-input>
              </v-flex>
              <v-flex lg12 md12 sm12 xs12 pa-0 v-if="!useSigned && useOtherField && !useSameCode">
                <v-text-field
                  :label="isChinessLanguage ? '笔记' : 'Ghi chú'"
                  v-model="currentItem.StoreCNNote"
                  class="input-group--focused"
                  @keyup.enter="focusNext"
                ></v-text-field>
              </v-flex>
              <v-flex lg12 md12 sm12 xs12 offset-md-8 pa-0>
                <v-btn @click="manualChanged">{{isChinessLanguage ? '输入和打印' : 'Nhập & in'}}</v-btn>
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex lg9 md12 sm12>
            <v-layout pl-4 pr-2 pt-12>
              <v-flex lg12 md12 sm12 xs12>
                <v-data-table 
                  :headers="headers"
                  :items="items"
                  hide-default-footer
                  disable-pagination
                  :must-sort="true"
                  :loading="loading"
                  :mobile-breakpoint="100"
                >
                  <template v-slot:item="{ item, index }">
                    <tr>
                      <td>{{ index + 1 }}</td>
                      <td v-if="!useSigned">{{ getCustomerName(item.UID) }}</td>
                      <td>{{ item.TransactionCode }}</td>
                      <td class="text-end">
                        <v-edit-dialog
                          :return-value.sync="item.ChinaWeight"
                          large
                          lazy
                          persistent
                          @save="saveItem({ID: item.ID, ChinaWeight: item.ChinaWeight, ProductAmount: getRandomArbitrary(10,250)})"
                        >
                          <div>{{ item.ChinaWeight | currency('', 1)}}</div>
                          <template v-slot:input>
                            <h-currency-input
                              v-model="item.ChinaWeight"
                              :decimal="1"
                              label="Kg"
                              autofocus
                            ></h-currency-input>
                          </template>
                        </v-edit-dialog>
                      </td>
                      <td class="text-end" v-if="!useSigned">
                        <v-edit-dialog
                          :return-value.sync="item.ChinaLength"
                          large
                          lazy
                          persistent
                          @save="saveItem({ID: item.ID, ChinaLength: item.ChinaLength})"
                        >
                          <div>{{ item.ChinaLength | currency }}</div>
                          <template v-slot:input>
                            <h-currency-input
                              v-model="item.ChinaLength"
                              :decimal="0"
                              :label="isChinessLanguage ? '长 (cm)' : 'Chiều dài (cm)'"
                              autofocus
                            ></h-currency-input>
                          </template>
                        </v-edit-dialog>
                      </td>
                      <td class="text-end" v-if="!useSigned">
                        <v-edit-dialog
                          :return-value.sync="item.ChinaWidth"
                          large
                          lazy
                          persistent
                          @save="saveItem({ID: item.ID, ChinaWidth: item.ChinaWidth})"
                        >
                          <div>{{ item.ChinaWidth | currency }}</div>
                          <template v-slot:input>
                            <h-currency-input
                              v-model="item.ChinaWidth"
                              :decimal="0"
                              :label="isChinessLanguage ? '宽 (cm)' : 'Chiều rộng (cm)'"
                              autofocus
                            ></h-currency-input>
                          </template>
                        </v-edit-dialog>
                      </td>
                      <td class="text-end" v-if="!useSigned">
                        <v-edit-dialog
                          :return-value.sync="item.ChinaHeight"
                          large
                          lazy
                          persistent
                          @save="saveItem({ID: item.ID, ChinaHeight: item.ChinaHeight})"
                        >
                          <div>{{ item.ChinaHeight | currency }}</div>
                          <template v-slot:input>
                            <h-currency-input
                              v-model="item.ChinaHeight"
                              :decimal="0"
                              :label="isChinessLanguage ? '高 (cm)' : 'Chiều cao (cm)'"
                              autofocus
                            ></h-currency-input>
                          </template>
                        </v-edit-dialog>
                      </td>
                      <td class="text-end">
                        <v-edit-dialog
                          :return-value.sync="item.RealNumberOfPack"
                          large
                          lazy
                          persistent
                          @save="saveItem({ID: item.ID, RealNumberOfPack: item.RealNumberOfPack})"
                        >
                          <div>{{ item.RealNumberOfPack | currency }}</div>
                          <template v-slot:input>
                            <h-currency-input
                              v-model="item.RealNumberOfPack"
                              :decimal="0"
                              :label="isChinessLanguage ? '数量' : 'Số lượng'"
                              autofocus
                            ></h-currency-input>
                          </template>
                        </v-edit-dialog>
                      </td>
                      <td v-if="!useSigned">
                        <v-edit-dialog
                          :return-value.sync="item.ProductType"
                          large
                          lazy
                          persistent
                          @save="saveItem({ID: item.ID, ProductType: item.ProductType})"
                        >
                          <div>{{ item.ProductType | display_value(product_types) }}</div>
                          <template v-slot:input>
                           <v-autocomplete clearable class="input-group--focused" :label="isChinessLanguage ? '分类' : 'Loại hàng'" v-model="item.ProductType" :items="product_types" item-text="name" item-value="value"></v-autocomplete>
                          </template>
                        </v-edit-dialog>
                      </td>
                      <td class="text-center">
                        <v-icon v-if="!useSigned" small v-bind:style="{ 'color': item.Packages && item.Packages.length > 0 ? '#dc3545' : 'gray'}" @click="duplicateItem(item.ID)">widgets</v-icon>
                        <v-icon v-if="!useSigned" small color="#dc3545" @click="printTransaction(item.ID)">fa-print</v-icon>
                        <v-icon small @click="deleteItem(item.ID)">fa-trash-alt</v-icon>
                      </td>
                    </tr>
                  </template>
                </v-data-table>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-card-text>
      <!-- <v-dialog v-model="innerDialog" persistent width="400px">
        <v-card>
          <v-card-title>{{isChinessLanguage ? '复制' : 'Tạo mã tách'}}</v-card-title>
          <v-card-text>
            <v-layout row wrap pa-3>
              <v-flex lg12 md12 sm12 xs12>
                <h-currency-input v-model="splitNumber" :decimal="0" :label="isChinessLanguage ? '数量' : 'Số lượng'" autofocus></h-currency-input>
              </v-flex>
            </v-layout>
          </v-card-text>
          <v-card-actions>
            <v-btn color="error" flat @click.stop="innerDialog = false">Cancel</v-btn>
            <v-btn color="success" flat @click.stop="onDuplicateItem">OK</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog> -->
      <split-package
            :dialogSplitPackage="innerDialog"
            :currentItem="currentItem"
            v-on:closePopup="innerDialog = false"
      ></split-package>
      <v-overlay :value="appLoading" z-index="99999999">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from "vuex";
import configs from "../../configs";
import _ from "lodash";
import { getCurrentTime, playSound } from "@/commons/utils";
import { print_transaction_v2, request_detail } from "@/commons/hpod";
import split_package from "@/components/popups/split_package";
import Vue from 'vue';
export default {
  name: "store-popup",
  components: {
    "split-package": split_package
  },
  data() {
    return {
      appLoading: false,
      useOtherField: false,
      useBarcodeReader: false,
      useSameCode: false,
      useTMDT: false,
      useSigned: false,
      innerDialog: false,
      splitItem: null,
      selectedUID: 0,
      selectedID: 0,
      selectedType: -1,
      splitNumber: 1,
      productAmount: 36.8,
      filterData: [
        {
          column: "ModifiedDate",
          value: getCurrentTime(),
          condition: "t_greater_than_or_equal"
        },
        {
          column: "Status",
          value: configs.PACKAGE_STATUS_ENUM.DA_VE_TQ,
          condition: "equal"
        }
      ],
      requireRules: [v => !!v || "Trường dữ liệu không được để trống."],
      pagination: {  ...configs.PAGINATION, itemsPerPage: 1000000, sortBy: ["StoreCNImportDate"]},
      items_per_page: { "items-per-page-options": [8] },
      currentTime: getCurrentTime()
    };
  },
  props: ["dialogStoreManager"],
  watch: {
    dialogStoreManager() {
      if (this.dialogStoreManager) {
        this.filterData = [
          {
            column: "ModifiedDate",
            value: getCurrentTime(),
            condition: "t_greater_than_or_equal"
          },
          {
            column: "Status",
            value: configs.PACKAGE_STATUS_ENUM.DA_VE_TQ,
            condition: "equal"
          }
        ];
        this.getListData();
        this.$nextTick(() => this.$refs.trancode.focus());
      }
    },
    useBarcodeReader() {
      if (this.useBarcodeReader) {
        this.$nextTick(() => this.$refs.trancode.focus());
      }
    },
    useSigned() {
      if (this.useSigned) {
        this.$nextTick(() => this.$refs.trancode.focus());
      }
    },
    useTMDT() {
      if (this.useTMDT) {
        this.currentItem.ProductNumber = 1;
        this.productAmount = 10;
        this.selectedType = 0;
      }
      else { 
        this.selectedType = -1;
      }
    },
    useSameCode() {
      if(this.useSameCode) {
        this.currentItem.RealNumberOfPack = 1;
      }
    }
  },
  computed: {
    ...mapState({
      productNames: state => state.orderTransaction.productNames.data,
      customers: state => state.customer.master.data,
      items: state => state.orderTransaction.listOfDay.data,
      total_rows: state => state.orderTransaction.listOfDay.total,
      loading: state => state.orderTransaction.loading,
      currentItem: state => state.orderTransaction.selected,
      configuration: state => state.configuration.selected
    }),
    chinaStoreID() {
      return this.$store.state.authentication.user.chinastoreid;
    },
    isChinessLanguage() {
      return (this.$store.state.authentication.user.roletype & configs.ROLE_TYPE.StoreTQ) == configs.ROLE_TYPE.StoreTQ && this.$store.state.authentication.user.ischinesslanguage;
    },
    product_types() {
      return this.configuration.ProductTypes ? JSON.parse(this.configuration.ProductTypes) : [];
    },
    headers() {
      if(this.useSigned) {
        return [
          {
            text: this.isChinessLanguage ? "No." : "STT",
            value: "",
            dataType: configs.DATA_TYPE["Number"]
          },
          {
            text: this.isChinessLanguage ? "单号" : "Mã bill",
            value: "TransactionCode",
            filterable: true,
            quickSearch: true,
            dataType: configs.DATA_TYPE["String"]
          },
          {
            text: this.isChinessLanguage ? "Kg" : "Kg",
            value: "ChinaWeight",
            align: "end",
            filterable: true,
            dataType: configs.DATA_TYPE["Number"]
          },
          {
            text: this.isChinessLanguage ? "数量" : "Số lượng",
            value: "RealNumberOfPack",
            align: "end",
            filterable: true,
            dataType: configs.DATA_TYPE["Number"]
          },
          {
            text: this.isChinessLanguage ? "操作" : "Thao tác",
            value: "",
            align: "center",
            sortable: false,
            filterable: false
          }
        ]
      }
      else {
        return [
          {
            text: this.isChinessLanguage ? "No." : "STT",
            value: "",
            dataType: configs.DATA_TYPE["Number"]
          },
          {
            text: this.isChinessLanguage ? "客户" : "K.Hàng",
            value: "UID",
            values:[],
            dataType: configs.DATA_TYPE["Enum"]
          },
          {
            text: this.isChinessLanguage ? "单号" : "Mã bill",
            value: "TransactionCode",
            filterable: true,
            quickSearch: true,
            dataType: configs.DATA_TYPE["String"]
          },
          {
            text: this.isChinessLanguage ? "Kg" : "Kg",
            value: "ChinaWeight",
            align: "end",
            filterable: true,
            dataType: configs.DATA_TYPE["Number"]
          },
          {
            text: this.isChinessLanguage ? "长" : "Dài",
            value: "ChinaLength",
            align: "end",
            filterable: true,
            dataType: configs.DATA_TYPE["Number"]
          },
          {
            text: this.isChinessLanguage ? "宽" : "Rộng",
            value: "ChinaWidth",
            align: "end",
            filterable: true,
            dataType: configs.DATA_TYPE["Number"]
          },
          {
            text: this.isChinessLanguage ? "高" : "Cao",
            value: "ChinaHeight",
            align: "end",
            filterable: true,
            dataType: configs.DATA_TYPE["Number"]
          },
          {
            text: this.isChinessLanguage ? "数量" : "Số lượng",
            value: "RealNumberOfPack",
            align: "end",
            filterable: true,
            dataType: configs.DATA_TYPE["Number"]
          },
          {
            text: this.isChinessLanguage ? "分类" : "Loại hàng",
            value: "ProductType",
            values: [],
            dataType: configs.DATA_TYPE["Enum"]
          },
          {
            text: this.isChinessLanguage ? "操作" : "Thao tác",
            value: "",
            align: "center",
            sortable: false,
            filterable: false
          }
        ]
      }
    }

  },
  methods: {
    getRandomArbitrary(min, max) {
      return Math.round((Math.random() * (max - min) + min) * 10)/10;;
    },
    focusNext(e) {
      const inputs = Array.from(e.target.ownerDocument.querySelectorAll('.input-group--focused input:not([type=hidden])'));
      const index = inputs.indexOf(e.target);
      if (index < inputs.length - 1) {
        inputs[index + 1].focus();
      }
      else {
        this.manualChanged();
      }
    },
    closePopup() {
      this.$emit("closePopup");
    },
    deleteItem(id) {
      this.$emit("deleteItem", id);
    },
    enterTransaction(e) {
      playSound('/vtb-success.mp3')
      this.focusNext(e);
    },
    checkTransaction(e) {
      if(this.currentItem.TransactionCode != '') {
        this.appLoading = true;
        let current_item = {...this.currentItem};
        this.$store.commit('orderTransaction/setDetail', {data: { 
          ID: 0, 
          TransactionCode: this.currentItem.TransactionCode, 
          FeeShip: 0, 
          ProductPrice: 15,
          RealNumberOfPack: 1,
          ChinaWeight: 0,
          ChinaLength: 0,
          ChinaWidth: 0,
          ChinaHeight: 0,
          ProductType: this.useSameCode && this.useOtherField ? current_item.ProductType : 0,
        }})
        request_detail('order_transactions', ret => {
          let item = ret.data;
          this.appLoading = false;
          if(item) {
            if(item.UID > 0) {
              this.selectedUID = item.UID;
              this.currentItem.UID = item.UID;
              this.currentItem.Username = this.getCustomerName(item.UID);
            }        
            this.currentItem.ID = item.ID;
            if(!this.useSameCode) {
              this.currentItem.ProductType = item.ProductType;
            }
            
            if(item.ProductType) {
              this.selectedType = this.currentItem.ProductType;
            }
            this.currentItem.StoreCNNote = item.StoreCNNote;
            this.currentItem.ProductName = item.ProductName;
            this.currentItem.ProductAmount = item.ProductAmount;
            this.productAmount = item.ProductAmount;
            this.currentItem.ProductNumber = item.ProductNumber || 1;
          }
          else if(!this.useOtherField && !this.useTMDT){
            this.manualChanged();
          }         
        }, Vue.http, '', this.$store.state.authentication.token, {'transaction_code': this.currentItem.TransactionCode});
      }
      if(this.useOtherField || this.useTMDT) {
        this.focusNext(e);
      }
    },
    onChangeWeight(e) {
      this.productAmount = this.getRandomArbitrary(10,250);
      if(!this.useSigned) {
        this.focusNext(e);
      }
      else { 
        this.manualChanged();
      }
    },
    saveItem(item) {
        this.$store.dispatch("orderTransaction/setDetail", {
          data: {
            ...item,
            ChinaStoreID: this.chinaStoreID
          },
          id: item.ID
        });
    },
    duplicateItem(id) {
      this.selectedID = id;
      this.$store.dispatch("orderTransaction/getDetail", id);
      this.innerDialog = true;
    },
    onDuplicateItem() {
      this.$store.dispatch("orderTransaction/duplicateDetail", {
        data: {id: this.selectedID, split_number: this.splitNumber}
      });
      this.innerDialog = false;
      this.splitNumber = 1;
    },
    manualChanged() {
      if (this.currentItem.TransactionCode != "" && !this.useBarcodeReader) {
        this.appendTransactionCode();
      }
    },
    codeChanged() {
      if (this.currentItem.TransactionCode != "" && this.useBarcodeReader) {
        this.appendTransactionCode();
      }
    },
    getCustomerName(uid) {
      const cus = _.find(this.customers, {ID: uid});
      return cus ? cus.CustomerCode : '';
    },
    getCustomerList() {
      this.$store.dispatch("customer/getCustomerList", {
          is_master: 1,
          fields: ["ID", "Username", "CustomerCode"]
      });
    },
    appendTransactionCode: _.debounce(
      function() {
        let current_item = {...this.currentItem};
        let payLoad = {
          TransactionCode: this.currentItem.TransactionCode,
          ChinaWeight: this.currentItem.ChinaWeight,
          ChinaLength: this.currentItem.ChinaLength,
          ChinaWidth: this.currentItem.ChinaWidth,
          ChinaHeight: this.currentItem.ChinaHeight,
          RealNumberOfPack: this.currentItem.RealNumberOfPack,
          StoreCNNote: this.currentItem.StoreCNNote,
          Status: configs.PACKAGE_STATUS_ENUM.DA_VE_TQ,
          TransactionView: 'store_cn_popup',
          PrintTemp: this.useTMDT && !this.useSigned,
          ChinaStoreID: this.chinaStoreID
        }
        if(this.selectedUID > 0) {
          payLoad.UID = this.selectedUID;
          payLoad.Username = this.getCustomerName(this.selectedUID);
        }
        if(this.selectedType > -1) {
          payLoad.ProductType = this.selectedType;
        }
        if(this.useTMDT) {
          payLoad.ProductAmount = this.getRandomArbitrary(10,250);
          payLoad.ProductNumber = this.currentItem.ProductNumber || 1;
          payLoad.ProductName = this.currentItem.ProductName;
          payLoad.ProductType = 0;
        }
        if(this.chinaStoreID) {
          this.filterData.push({
            column: "ChinaStoreID",
            value: this.chinaStoreID,
            condition: "equal"
          });
        }
        this.$store.dispatch("orderTransaction/setDetail", {
          data: payLoad,
          id: this.currentItem.ID,
          param: {
            pagination: this.pagination,
            filter: this.filterData,
            quickFilter: []
          },
          is_print: this.useTMDT && !this.useSigned,
          ignore_commit: true
        });
        this.$nextTick(() => this.$refs.trancode.focus());
        setTimeout(this.$store.commit('orderTransaction/setDetail', {data: { 
          ID: 0, 
          TransactionCode: "", 
          FeeShip: 0, 
          ProductPrice: 15,
          ChinaWeight: 0,
          ChinaLength: 0,
          ChinaWidth: 0,
          ChinaHeight: 0,
          ProductType: 0,
          RealNumberOfPack: 1,
        }}), 100);
      },
      100,
      {
        leading: false,
        trailing: true
      }
    ),
    getListData() {
      if(this.chinaStoreID) {
        this.filterData.push({
          column: "ChinaStoreID",
          value: this.chinaStoreID,
          condition: "equal"
        });
      }
      var param = {
        pagination: this.pagination,
        filter: this.filterData,
        quickFilter: []
      };
      this.$store.dispatch("orderTransaction/getListOfDay", param);
      this.$store.dispatch("orderTransaction/getProductNames");
    },
    printTransaction(id) {
      this.$store.commit("orderTransaction/setLoading", { loading: true });
      request_detail('order_transactions/print', ret => {
        let item = ret.data;
        this.$store.commit("orderTransaction/setLoading", { loading: false });
        const frame_printing = print_transaction_v2(item, this.$store.state.authentication.user.username, true, null, item.Packages != null);
        var doc = document.getElementById('print_frame').contentWindow.document;
        doc.open();
        doc.write(frame_printing);
        doc.close();
        setTimeout(function(){ window.frames["print_frame"].window.print(); }, 1000);
      }, Vue.http, id, this.$store.state.authentication.token);
    }
  },
  mounted() {
    this.getCustomerList();
    this.$store.dispatch("configuration/getDetail", 1);
    this.$root.$on("REQUEST_REFRESH_DATA", this.getListData);
  },
  beforeDestroy() {
    this.$root.$off("REQUEST_REFRESH_DATA", this.getListData);
  }
};
</script>
